footer {
    font-family: "Heebo", sans-serif;
    font-size: 1.2rem;
    color: white;
    padding: 2rem 0;
    font-weight: 200;

    .row {
        #contactDataContainer {
            ul {
                padding-left: 0.2rem;
                a {
                    color: #25D366;
                }

                li div {
                    margin: 0
                }
                span{
                    margin-left:.3rem;
                }
            }
        }
    }
}

/***********************/
/*Laptops and desktops*/
@media only screen and (min-width: 992px) {
    footer {
        background-image: url("../../assets/fondoAzul.jpg");
        background-size: cover;
        background-position: center;
        position: relative;
        min-height: 20rem;

        #contactDataContainer {
            display: flex;
            align-items: center;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-image: linear-gradient(to right,
                    rgba(0, 0, 0, 1),
                    rgba(0, 0, 0, 0.9),
                    rgba(0, 0, 0, 0.6),
                    rgba(0, 0, 0, 0.2));

            div {
                margin-left: 3rem
            }
        }
    }
}

/***********************/
/*Tablets and phones*/
@media only screen and (max-width: 992px) {
    footer {
        background-color: #212529;

        #contactDataContainer {
            padding: 1.5rem 0 0 0;

            div {
                text-align: center;
            }
        }
    }
}