

#gallerySection {
    background: linear-gradient(135deg, rgba(220, 220, 220, 1) 0%, rgba(245, 241, 237, 1) 75%);

    .card {
        font-family: "Heebo", sans-serif;
        text-align: center;
        margin-bottom: 3.5rem;
        box-shadow: -5px 6px 5px #aaaaaa;
        font-weight: 200;

        .MuiSkeleton-root{
            width: 100%;
            height: 100%;
        }

        .card-body {
            padding: 0;

            .card-title {
                font-size: 1.7rem;
            }

            .list-group {
                border-radius: 0px;

                .list-group-item {
                    display: flex;
                    flex-direction: column;
                    border: none;
                    background-color: #f8f8f8;

                    //price
                    span:nth-child(1) {
                        margin-bottom: .2rem;
                    }

                    //price
                    span:nth-child(2) {
                        font-family: "Work Sans", "Heebo", sans-serif;
                        font-weight: 300;
                    }
                }
            }

            .containerML {
                cursor: pointer;

                a {
                    display: block;
                    width: 100%;
                    height: 100%;
                    padding: 1.5rem 0 1.2rem 0;
                }

                .logoML {
                    height: 50px;
                }
            }
        }
    }

    #noCars {
        display: flex;
        flex-direction: column;
        text-align: center;
        padding: 3rem 0;
    }
}

/***********************/
/*Phones and tablets*/
@media only screen and (max-width: 992px) {
    #cardsMapper .row {
        justify-content: center;
    }
}

/***********************/
/*Desktops and laptops*/
@media only screen and (min-width: 992px) {
    #cardsMapper .row {
        justify-content: space-around;
    }

    //btn animation
    .containerML {
        font-weight: bold;
        cursor: pointer;
        transition: all 0.3s ease;
        position: relative;
        outline: none;
        overflow: hidden;
    }

    .containerML:hover {
        padding-right: 24px;
        padding-left: 8px;
    }

    .containerML:hover:after {
        opacity: 1;
        right: 75px;
    }

    .containerML:after {
        content: "\00BB";
        position: absolute;
        opacity: 0;
        font-size: 20px;
        line-height: 40px;
        top: 25px;
        right: -20px;
        transition: 0.4s;
    }

}