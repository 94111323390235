
//img bckg
header::before {
    content: "";
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    opacity: 0.75;
}

header {
    position: relative;

    .row {
        justify-content: flex-start;
        align-items: center;

        h1 {
            color: white;
            text-shadow: 1px 1px 3px black;
            position: relative;
            font-family: "Noto Sans", sans-serif;
        }

        img {
            height: auto;
            position: relative;
        }
    }
}



/***********************/
/*Tablets and phones*/
@media only screen and (max-width: 992px) {
    header::before {
        background-image: url("../../assets/fondoAzul.jpg");
        background-position: -10px -500px;
    }

    #logoContainer {
        display: flex;
        justify-content: center;

        img {
            width: 50%;
        }
    }
}

/***********************/
/*Tablets, Laptops and Desktops*/
@media only screen and (min-width: 576px) {
    header::before {
        background-image: url("../../assets/fondoRojo.png");
        background-position: 0px -200px;
        background-size: cover;
    }

    header {
        h1 {
            text-align: left
        }

        img {
            width: 100%;
        }
    }
}