#signatureSection {
    background-color: #4b4e58;
    color: #a99985;

    a {
        color: #a99985;
    }
}

/***********************/
/*Laptops and desktops*/
@media only screen and (min-width: 992px) {
    #signatureSection {
        font-size: .8rem;
        text-align: right;
        padding-right: 1rem;
    }
}

/***********************/
/*Tablets and phones*/
@media only screen and (max-width: 992px) {
    #signatureSection {
        font-size: .9rem;
        text-align: center;
        border-bottom: 1px solid #4b4e58;
    }
}