#root {
	font-size: 16px;
}

ul {
	padding: 0;

	li {
		list-style: none;
	}
}

/*Phones*/
@media only screen and (max-width: 768px) {
	#root {
		text-align: center;
	}
}