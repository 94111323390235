/***********************/
/*Laptops and Desktops*/
@media only screen and (min-width: 992px) {
    #filtersDktp {
        margin-bottom: 2rem;

        .row {
            justify-content: space-evenly;

            &:nth-child(1) {
                /* ff 3.6+ */
                background: -moz-linear-gradient(0deg, rgba(213, 220, 220, 1) 0%, rgba(222, 230, 230, 1) 13%, rgba(244, 245, 245, 1) 62%);
                /* safari 5.1+,chrome 10+ */
                background: -webkit-linear-gradient(0deg, rgba(213, 220, 220, 1) 0%, rgba(222, 230, 230, 1) 13%, rgba(244, 245, 245, 1) 62%);
                /* opera 11.10+ */
                background: -o-linear-gradient(0deg, rgba(213, 220, 220, 1) 0%, rgba(222, 230, 230, 1) 13%, rgba(244, 245, 245, 1) 62%);
                /* ie 6-9 */
                filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#D5DCDC', endColorstr='#F4F5F5', GradientType=1);
                /* ie 10+ */
                background: -ms-linear-gradient(0deg, rgba(213, 220, 220, 1) 0%, rgba(222, 230, 230, 1) 13%, rgba(244, 245, 245, 1) 62%);
                /* global 94%+ browsers support */
                background: linear-gradient(0deg, rgba(213, 220, 220, 1) 0%, rgba(222, 230, 230, 1) 13%, rgba(244, 245, 245, 1) 62%);
            }

            .filterCol {
                display: flex;
                flex-direction: column;
                padding-top: 1rem;
            }
        }
    }
}

/***********************/
/*Phones and tablets*/
@media only screen and (max-width: 992px) {
    .navbar {
        padding: 0 !important;
        margin-bottom: 2.5rem;
        

        button {
            width: 100%;
            height: 3.5rem;
            border-radius: 0;
            background-color: #343A40;
            color: #F8F9FA;
            box-shadow: none!important;
        }

        .navbar-collapse {
            background:linear-gradient(180deg, rgba(206, 212, 218, 1) 6%, rgba(222, 226, 230, 1) 18%, rgba(233, 236, 239, 1) 100%);
            // padding-top:1.3rem;
            text-align: center;
            .yearsFilter{
                // padding-bottom:1.6rem;
            }
            .filterCompCont{
                //Km
                &:nth-child(1){
                    padding-top:1.3rem;
                }
                //Price
                &:nth-child(2){
                    padding:1.3rem 0px;
                    border-top:1px solid lightgrey;
                    border-bottom:1px solid lightgrey;
                }
                //Years
                &:nth-child(3){
                    padding:1.3rem 0px;
                    span{
                        display: block;
                    }
                }
            }
        }
        
    }
}
