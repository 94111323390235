
#filterBottomBtns {
    display: flex;

    .filterBtn {
        width: 50%;
        font-family: "Roboto", sans-serif;

        //filterCleaner
        &:nth-child(1) {
            border-radius: 0;
            padding: 0.2rem 0px;
            border: 1px solid #565D63;
            position: relative;
            transition: 0.3s ease all;
            z-index: 1;

            &:before {
                transition: 0.5s all ease;
                position: absolute;
                top: 0;
                left: 50%;
                right: 50%;
                bottom: 0;
                opacity: 0;
                content: '';
                background-color: #495057;
                z-index: -1;
            }

            &:hover {
                color: white;
            }

            &:hover:before {
                transition: 0.5s all ease;
                left: 0;
                right: 0;
                opacity: 1;
            }
        }

        //cardCounter
        &:nth-child(2) {
            display: flex;
            justify-content: center;
            align-items: center;
            border: 1px solid black;
            background-color: #f5f5f5;
            cursor: default;
        }
    }
}

/***********************/
/*Laptops and Desktops*/
@media only screen and (min-width: 992px) {
    #filtersDktp {
        .row {
            //filterBottomBtns
            &:nth-child(2) {
                .col-xxl-12 {
                    padding: 0;
                }
            }
        }
    }
}